.home {
  /* background-color: green; */
  position: relative;
}
.home .content {
  position: absolute;
  display: flex;
  width: 100%;

  z-index: 10;
  top: 0;
  left: 0;
  min-height: 100%;
  right: 0;
}
.content-padding {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  top: 15rem;
}
.home .content .content-desc {
  align-self: flex-start;

  /* background-color: #fff; */
  background: rgba(0, 0, 0, 0.249);
  margin: 10px;
  padding: 10px;
  border-radius: 20px;
  text-align: center;
}
.home .content .content-desc a,
.home .content .content-desc h1 {
  color: #fff;
  text-transform: uppercase;
}
.home .content .content-desc a {
  text-decoration: none;
}
.home .content .content-desc a span {
  padding: 10px;
  margin: 10px 3rem;
  border-radius: 8px;
  color: #fff;
}
.home div#slider {
  display: block;
  overflow: hidden;
  height: 100%;
  max-height: 100vh;
}
.home div#slider figure {
  background-color: black;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}
.home div#slider figure img {
  width: 20%;

  float: left;

  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.home div#slider figure {
  position: relative;
  width: 500%;
  margin: 0;
  left: 0;
  text-align: left;
  font-size: 0;
  animation: 30s slidy infinite;
}

@keyframes slidy {
  0% {
    left: 0%;
  }
  20% {
    left: 0%;
  }
  25% {
    left: -100%;
  }
  45% {
    left: -100%;
  }
  50% {
    left: -200%;
  }
  70% {
    left: -200%;
  }
  75% {
    left: -300%;
  }
  95% {
    left: -300%;
  }
  100% {
    left: -400%;
  }
}

.goy-home-idea {
  /* position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%; */
}
.goy-home-idea h1 {
  margin-top: 10rem;
}

/* @media (max-width: 500px) {
  .home .content .content-desc a {
    text-decoration: none;
    color: #fff;
  }
} */
