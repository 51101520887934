.top-nav {
  /* padding: 10px; */
  display: flex;
  align-items: center;
  /* background: #ebeef1; */
  background: #005a8c;
  position: sticky;
  color: #fff;
  top: 0;
  z-index: 1000;
}
.top-nav .logo {
  margin: auto 10px;
}
.checkbtn {
  font-size: 30px;
  color: #fff;
  padding: 10px;
  display: none;
  cursor: pointer;
}
#humberger {
  display: none;
  cursor: pointer;
}
.top-nav .nav {
  flex-grow: 1;
  list-style: none;
  text-decoration: none;
  display: flex;
}
.top-nav .nav-list {
  text-decoration: none;
  padding: 10px;
  margin: 1rem;
  /* border-bottom: 2px solid red; */
}

.top-nav .nav li:nth-of-type(1) {
  margin-left: auto;
}
.top-nav .nav li:nth-of-type(5) {
  margin-left: auto;
}

.top-nav .nav-list .nav-link {
  position: relative;
  text-decoration: none;
  /* color: black; */
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
}
.top-nav .nav-list .nav-link::after {
  content: "";
  position: absolute;

  left: 0;
  bottom: -10px;
  width: 0%;
  height: 3px;
  background-color: red;
  transition: 0.3s;
}
.top-nav .nav-list .nav-link:hover:after {
  width: 100%;
}
.top-nav .nav-list .active {
  color: rgb(249, 180, 20);
}
.top-nav .nav-list .active::after {
  width: 100%;
}

/* /////////////////////////////////////////////////////////////////////////////// */
@media (max-width: 500px) {
  .top-nav .nav {
    position: fixed;
    top: 0;
    bottom: 0;
    right: -100%;
    width: 100%;
    margin: auto;
    /* height: 100vh; */
    /* background-color: #010; */
    background: #005a8c;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* color: #fff; */
    transition: all 0.5s;

    /* display: none; */
  }
  .top-nav .nav-list {
    color: #fff;
    /* text-decoration: none;
  padding: 10px;
  margin: 1rem; */
    /* border-bottom: 2px solid red; */
  }
  .top-nav .nav li:nth-of-type(1) {
    margin-left: 1rem;
  }

  .checkbtn {
    margin-left: auto;
    display: block;
  }
  #humberger:checked ~ ul {
    /* display: block; */
    right: 0%;
  }
}
/* /////////////////////////////////////////////////////////////////////////////// */
