#services {
  background-color: #fff;
  color: black;
  padding: 20px;
}
.service-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  /* max-width: 300px; */
  /* max-width: 300px; */
}
.text-container {
  margin-top: 3rem;
  padding: 20px;

  /* display: block; */
  /* background-color: rgba(0, 0, 0, 0.05); */
  width: 100%;
  /* justify-content: center; */
}

.text-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.services-list,
.service-container p {
  margin-top: 2rem;
  max-width: 600px;

  text-align: left;
}
.services-list {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.bullets {
  padding: 10px auto;
  display: flex;
}
.bullets .bullets-icon {
  color: green;
  font-size: 2rem;
  padding-right: 10px;
  /* width: 50px; */
}
.bullets .bullets-text {
  font-size: 1.2rem;
  text-transform: capitalize;
}
