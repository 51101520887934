/* .footer {
  background-color: black;
  color: #fff;

  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
} */

/*=======================footer================*/

.footer {
  background-color: #24262b;
  padding: 70px 0;
  display: flex;
  justify-content: center;
  gap: 5rem;
}
.footer .social-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-decoration: none;
  gap: 5px;
  color: white;
  border-radius: 50%;
  transition: all 0.3s;
}
.footer .social-icons .icons {
  width: 2rem;
  height: 2rem;
}
.footer .social-icons:hover {
  scale: 1.2;
}
/*responsive*/
@media (max-width: 790px) {
  .footer {
    flex-wrap: wrap;
  }
}
@media (max-width: 574px) {
  .footer-col {
    width: 100%;
  }
}

/*=======================footer=================*/
