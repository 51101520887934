#about-us {
  /* background-color: black;
  color: #fff; */
  background-color: #fff;
  color: black;
  padding: 20px;
}
.about-main-content {
  display: flex;

  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%;
}
.about-content {
  /* max-width: 350px; */
  max-width: 800px;

  margin-left: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* display: block; */
  margin-top: 3rem;
  margin-bottom: 3rem;

  /* margin-right: auto; */

  padding: 20px;
  background: #fff;
  /* display: block; */

  /* width: 100%;
   */
}
.about-content img {
  /* width: 300px; */
  height: 400px;
  width: 100%;
}
.first img {
  max-width: 300px;
}
.about-content p {
  margin-top: 2rem;

  padding: 10px 20px;
  width: 100%;

  text-align: left;
  /* font-size: 0.8rem; */
  line-height: 1.7rem;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
@media screen and (max-width: 1400px) {
  .about-main-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .about-content img {
    /* width: 300px; */
    height: auto;
    width: 100%;
  }
  .about-content {
    flex-direction: column;
    padding: 0;
  }
}

@media screen and (max-width: 700px) {
  .about-content {
    flex-direction: column;
    padding: 0;
  }
}
