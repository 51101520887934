.nav-hero {
  /* background: black;
  color: #fff; */
  height: 200px;
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: center;
  background-image: url("../../../../public/images/external-car-view.jpeg");
  background-position: center;
}
.nav-hero h1 {
  text-transform: uppercase;
}
