html {
  scroll-behavior: smooth;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  /* background: #ebeef1; */
  /* background: #10101010; */
  background-color: black;
  background-image: url("../public/images/external-car-view.jpeg");
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  backface-visibility: hidden;
  background-size: cover;

  background-blend-mode: normal;
  /* min-height: 100vh;
  display: flex;
  justify-content: center; */
  /* align-items: center; */
}
/* internal-car-view.jpeg */

.main {
  min-height: 100vh;
  margin-bottom: 5rem;
  /* background: -webkit-linear-gradient(left, #7579ff, #b224ef); */
}
.main h1 {
  text-align: center;
}
